const Data = [
    {
        id: 1,
        text: "Study React Pre-Class Notes",
        // date: "Dec 12th at 2:30pm",
        date: "2023-03-21T15:57Z",
        done: false,
    },
    {
        id: 2,
        text: "Feed the Dog",
        date: "2023-03-22T15:57Z",
        done: true,
    },
    {
        id: 3,
        text: "Attend In-Class",
        date: "2023-03-25T15:57Z",
        done: false,
    },
];
export default Data;
